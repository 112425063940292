import React from 'react';
import Total from "../../Total/Total";
import {
    setWorkplaceSavingsTotalSpouse,
} from "../../../../../store/actions/savingsAndInvestments";
import { connect } from "react-redux";

const WorkplaceSavingsSpouseTotal = ( props ) => {
    const onChange = val => {
        props.setWorkplaceSavingsTotalSpouse(val)
    }

    return (
        < Total
            total={props.workplaceSavingsTotal}
            title={'Total in RRSP'}
            onChange={onChange}
        />
    )
};

function mapStateToProps(state) {
    return {
        workplaceSavingsTotal: state.savingsAndInvestments.spouse.workplaceSavings.total
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setWorkplaceSavingsTotalSpouse : val => dispatch(setWorkplaceSavingsTotalSpouse(val))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkplaceSavingsSpouseTotal);