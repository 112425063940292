import React from 'react';
import Total from "../../Total/Total";
import { setWorkplaceSavingsTotalYou } from "../../../../../store/actions/savingsAndInvestments";
import { connect } from "react-redux";

const WorkplaceSavingsYouTotal = ( props ) => {
    const onChange = val => {
        props.setWorkplaceSavingsTotalYou(val)
    }

    return (
        < Total
            total={props.workplaceSavingsTotal}
            title={'Total in Workplace Savings'}
            onChange={onChange}
        />
    )
};

function mapStateToProps(state) {
    return {
        workplaceSavingsTotal: state.savingsAndInvestments.you.workplaceSavings.total
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setWorkplaceSavingsTotalYou : val => dispatch(setWorkplaceSavingsTotalYou(val))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkplaceSavingsYouTotal);