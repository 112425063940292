import React, { useEffect } from 'react';
import Layout from "../../../../hoc/Layout/Layout";
import { Helmet } from "react-helmet";
import InnerLayoutStep from "../../../../hoc/InnerLayoutStep/InnerLayoutStep";
import InnerLayoutStepContent from "../../../../hoc/InnerLayoutStepContent/InnerLayoutStepContent";
import Navigation from "../../../../components/Navigation/Navigation";
import WorkplaceSavingsYouTotal
    from "../../../../components/ContentRow/Step3/WorkplaceSavings/WorkplaceSavingsTotal/WokplaceSavingsYouTotal";
import { connect } from "react-redux";
import WorkplaceSavingsSpouseTotal
    from "../../../../components/ContentRow/Step3/WorkplaceSavings/WorkplaceSavingsTotal/WokplaceSavingsSpouseTotal";
import { thirdStepCompleted } from "../../../../store/actions/savingsAndInvestments";
import { retirementCalcLinks } from "../../../../Routes/RoutesConst";

const WorkplaceSavingsHave = ( props ) => {
    const titles = {
        layoutTitle: 'WORKPLACE SAVINGS',
        sectionTitle: 'How much do you have?',
    };

    const title = "Current workplace savings";

    const next = retirementCalcLinks.workplaceSavingsContribute;

    useEffect(() => {
        props.thirdStepCompleted(false)
    }, [props])

    return (
        <Layout
            title={titles.layoutTitle}
            progressStep3={(4/16)*100}
            active={'step3'}
        >
            <Helmet>
                <title>{title}</title>
            </Helmet>

            <InnerLayoutStep
                modalContent=''
                sectionTitle={titles.sectionTitle}
                modal={false}
            >

                {
                    props.you.workplaceSavings.exist &&
                    <InnerLayoutStepContent type={'you'}>
                        <WorkplaceSavingsYouTotal/>
                    </InnerLayoutStepContent>
                }

                {
                    props.isSpouse && props.spouse.workplaceSavings.exist &&
                    <InnerLayoutStepContent type={'spouse'}>
                        <WorkplaceSavingsSpouseTotal/>
                    </InnerLayoutStepContent>
                }

            </InnerLayoutStep>

             <Navigation
                 isCompleted={true}
                 next={next}
             />

        </Layout>
    )
};

function mapStateToProps(state) {
    return {
        isSpouse: state.aboutYou.myFamily.spouseData.isSpouse,
        you: state.savingsAndInvestments.you,
        spouse: state.savingsAndInvestments.spouse,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        thirdStepCompleted: (val) => dispatch(thirdStepCompleted(val))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkplaceSavingsHave);